export class LoadingScreen{

    constructor(appManager){
        this.appManager = appManager

        this.initialise()
    }

    initialise(){
        this.LoadingScreen = document.getElementById("loadingScreen")
        this.LoadingLogo = document.getElementById("loadingLogo")
        this.LoadingLogo.src = this.appManager.data.app_logo
    }

    disable(){
        this.LoadingScreen.classList.add("hide")
    }

    enable(){
        this.LoadingScreen.classList.remove("hide")
    }
}