import { Light } from "../Objects/light"

const CAMERA_RADIUS = 0.8
const CAMERA_RADIUS_MIN = 0.5
const CAMERA_RADIUS_MAX = 5
const CAMERA_FOCUS_POINT = new BABYLON.Vector3(0,0.1,0)

export class CameraManager{  
    constructor(appManager){
        this.appManager = appManager
        this.camera = this.createArcCamera()
        this.cameraTarget = BABYLON.MeshBuilder.CreateTorus('marker', { diameter: 0.11, thickness: 0.05, tessellation: 32 });
        this.cameraTarget.scaling = new BABYLON.Vector3(0,0,0)
        this.light = new Light(appManager)

        //this.cameraTarget.position = CAMERA_FOCUS_POINT
    }

    createArcCamera() {
        let camera = new BABYLON.ArcRotateCamera("camera", 5 * Math.PI / 4, Math.PI / 2.5, 0.8, CAMERA_FOCUS_POINT, this.appManager.scene)
        camera.minZ = 0.01
        camera.allowUpsideDown = false
        camera.wheelPrecision = 150
        camera.pinchDeltaPercentage = 0.005
        camera.useNaturalPinchZoom = false
        camera.pinchPrecision = 0.005
        camera.panningSensibility = 0
        camera.radius = CAMERA_RADIUS
        camera.lowerRadiusLimit = CAMERA_RADIUS_MIN
        camera.upperRadiusLimit = CAMERA_RADIUS_MAX
        camera.attachControl(document.getElementById("renderCanvas"), true)
        camera.rebuildAnglesAndRadius()
        this.setUpCameraAutoRotation(camera)
        //this.appManager.scene.createDefaultEnvironment();
        this.appManager.scene.environmentTexture = new BABYLON.CubeTexture("./content/misc/environment (7).env", this.appManager.scene);
        
        return camera;
    }

    setUpCameraAutoRotation(camera){
        camera.useAutoRotationBehavior = true
        camera.autoRotationBehavior.idleRotationSpeed = 0.1
        camera.autoRotationBehavior.idleRotationWaitTime = 2000
        camera.autoRotationBehavior.idleRotationSpinupTime = 2000
        camera.autoRotationBehavior.zoomStopsAnimation = true
        camera.useAutoRotationBehavior = true
    }
    
    resetCameraTarget(){
        this.camera.target = this.cameraTarget
    }

    zoomCameraTo(value){
        let currentRadius = this.camera.radius
        this.tweenIn = gsap.fromTo( this.camera,{ radius: currentRadius, ease: "sine.in" }, { radius: value, duration: 0.3, ease: "sine.in", onComplete: () => {
            /* setTimeout(() => {
                if(experience.event != "loadHotspot")
                    this.appManager.rotatePrompt.initialise();
                }, 100) */
        }});
    }
}