export class HomeScreen{

    constructor(appManager){
        this.appManager = appManager

        this.initialise()
    }

    initialise(){
        this.topBarElement = document.querySelector(".mdc-top-app-bar")
        this.homeScreen = document.getElementById('homeScreen')
        this.homeScreenBackground = document.getElementById('homeScreenBackground')
        this.overlaidInstructions = document.getElementById('overlaidInstructions')
        this.prompt = document.getElementById('overlaidInstructionsText')
        this.experienceTitle = document.getElementById('experienceTitle')
        this.menuLogo = document.getElementById("menuLogo")
        this.menuLogo.src = this.appManager.data.appLogo

        this.setUpButtons()
    }

    setUpButtons(){
        this.homescreenButton = document.getElementById('buttonOption')
        this.experiences = this.appManager.data.menu_buttons

        this.experiences.forEach(experience => {
            let element = this.homescreenButton.cloneNode(true)
            element.classList.remove("hide")
            this.homeScreen.appendChild(element)
            if(experience.color != undefined)
                element.style.backgroundColor = experience.color;
            element.childNodes.forEach((child) => {
                if(child.innerHTML === "title"){
                    child.innerHTML = experience.title
                }
                else if(child.innerHTML === "icon"){
                    child.innerHTML = experience.icon
                }
            })
            element.onclick = () => this.loadExperience(experience);
        })

        const buttonHome = document.getElementById('buttonHome')
        buttonHome.onclick = () => this.displayHomeScreen();
    }

    loadExperience(experience){
        if(experience.type == "default")
            this.loadScene(experience.scene);
        else if(experience.type == "link")
            window.open(experience.url, '_blank');
    }

    displayHomeScreen(){
        let lastScene = this.appManager.sceneHistory[this.appManager.sceneHistory.length-1]
        this.appManager.sceneHistory[this.appManager.sceneHistory.length-1]
        if(lastScene != "h" && lastScene != undefined) {
            let scene = this.appManager.sceneManager.getSceneById(lastScene)
            scene.setHotspotVisibility(false)
        }
        this.homeScreen.classList.remove("hide")
        this.homeScreenBackground.classList.remove("hide")
        this.topBarElement.classList.add("hide")
        this.overlaidInstructions.classList.add("hide")
        this.appManager.cameraManager.zoomCameraTo(0.8)
        this.appManager.cameraManager.camera.useAutoRotationBehavior = true;
        this.appManager.transparentController.setAllTransparent(false)
        this.appManager.modelManager.currentModel.animationController.animationGroups[0].stop()
        this.appManager.modelManager.currentModel.animationController.animationGroups[0]
            .start(false, 1, 0, 0);
        this.appManager.sceneHistory.push("h")
    }

    loadScene(sceneId){
        this.appManager.sceneManager.loadScene(sceneId)
        this.hideHomeScreen()
    }

    hideHomeScreen(){
        this.homeScreen.classList.add("hide")
        this.homeScreenBackground.classList.add("hide")
        this.topBarElement.classList.remove("hide")
        this.appManager.cameraManager.camera.useAutoRotationBehavior = false;
    }
}